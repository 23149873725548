jQuery(function($) {
   var submit_tabs = {
       submitMessage: $(".submit-message"),

       init : function() {
           if (this.submitMessage.length > 0) {
               $("html, body").animate({
                   // add some spacing so its not obscured
                   scrollTop: this.submitMessage.offset().top - (document.body.clientHeight / 100 * 10)
               });
           }

           var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
           if (isMobile) {
               $("body.submit .submit-icons").responsiveTabs({
                   accordionOn: ["xs", "sm"]
               });
               $('body.submit .accordion-link').removeClass('active');
               $('body.submit .tab-pane').removeClass('active').addClass('hidden');
           }

           $('input[name="word_count"]').inputmask('9{*}');


       }
   };

   var submit_form = {
        already_submitted: false,
       init: function() {
           $('select[name=hear_about]').change(this.showHideTagLine);
           $('input[name=agent]').change(this.showHideAgentName);
           $('textarea[name=tagline]').on('keyup blur', this.changeRemainingMaxCharacters);
           $('#submit_manuscript').submit(this.preventMultipleSubmissions);
       },

       showHideAgentName: function(e) {
         if (!$(this).is(':checked')) {
           $('input[name=agent_name]').slideUp('slow').val("").trigger('blur');
         } else {
           $('input[name=agent_name]').slideDown('slow').trigger('blur');
         }
       },

       showHideTagLine: function(e) {
           if (!$(this).val() || $(this).val() === '') {
               $('textarea[name=tagline]').slideUp('slow').trigger('blur');
               $('div#tagline_remaining_characters').slideUp('slow');
           } else {
               $('textarea[name=tagline]').slideDown('slow').trigger('blur');
               $('div#tagline_remaining_characters').slideDown('slow');
           }
       },

       changeRemainingMaxCharacters: function(e) {
           var count = $(this).val().length;
           var $span = $('div#tagline_remaining_characters');
           $span.find(".number").html(150 - count);
           if (count === 0) {
               $span.removeClass('text-warning').removeClass('text-danger').addClass('text-success');
           } else if (count > 0 && count < 126) {
               $span.removeClass('text-success').removeClass('text-danger').addClass('text-warning');
           } else {
               $span.removeClass('text-warning').removeClass('text-success').addClass('text-danger');
           }
       },
     preventMultipleSubmissions: function(e) {
       if (this.already_submitted) {
         e.preventDefault();
       } else {
         this.already_submitted = true;
         var button = document.querySelector('button[name="submit"]');
         button.setAttribute('style', 'background-color: lightgray !important; color: gray !important; cursor: default !important;');
         button.disabled = true;
       }
     }
   };

   submit_tabs.init();
   submit_form.init();
});
