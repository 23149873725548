jQuery(function($) {
  var checkout_submit = {
    init : function() {
      if ($('.securepay_payment_form').length > 0) {
        this.limitCheckoutSubmit();
      }
    },



    validateFields: function(showError) {
      $('input#jsPayButton').removeAttr('disabled');
      var error = [];
      // Card number
      if ($('input#securepay_card_number').data('validated') !== true) {
        error.push("Please enter a valid credit card number.");
      }
      // Expiry date
      if ($('select#cc-expire-month').val() === '' || $('select#cc-expire-year').val() === '') {
        error.push("Please enter a valid expiry date.");
      }
      var tdate = new Date();
      var year = tdate.getFullYear().toString();
      var month = tdate.getMonth() + 1;
      if (month.length === 1) {
        month = '0' + month;
      }
      if ($('select#cc-expire-month').val() < month && $('select#cc-expire-year').val() === year) {
        error.push("Please enter an expiry date in the future.");
      }
      // CCV
      if ($('input#securepay_card_ccv').val().length < 3 || $('input#securepay_card_ccv').val().length > 4) {
        error.push("Please enter a valid card security code.");
      }
      if (error.length === 0) {
        $('#securepay_error_message').hide().html('');
        return true;
      } else if (showError === true) {
        var error_string = error.join("<br />");
        $('#securepay_error_message').show().html(error_string);
        return false;
      } else {
        return false;
      }
    },

    limitCheckoutSubmit : function() {
      $('input#jsPayButton').on('click',function(e) {
        if (!checkout_submit.validateFields(true)) {
          e.preventDefault();
          return false;
        } else {
          return true;
        }
      });

      $('form.securepay_payment_form').submit(function(e) {
        var $modal = $("#checkout-refresh-modal");
        if ($modal.length > 0) {
          $modal.modal();
        }
        $(this).find('input[type=submit]').attr('disabled','disabled');
      });


      //$('input#jsPayButton').attr('disabled', 'disabled');
      $('input#securepay_card_number').keyup(function() {
        var number = $(this).val();
        number = number.replace(/[^0-9]/g, '');
        var re = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
        if (number.match(re) != null) {
          $('input#jsCardType').val('visa');
          $('input#securepay_card_number').data('validated',true);
          checkout_submit.validateFields(false);
          return;
        }
        re = new RegExp("^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$");
        if (number.match(re) != null) {
          $('input#jsCardType').val('mastercard');
          $('input#securepay_card_number').data('validated',true);
          checkout_submit.validateFields(false);
          return;
        }
        re = new RegExp("^3[47][0-9]{13}$");
        if (number.match(re) != null) {
          $('input#jsCardType').val('amex');
          $('input#securepay_card_number').data('validated',true);
          checkout_submit.validateFields(false);
          return;
        }
        re = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$");
        if (number.match(re) != null) {
          $('input#jsCardType').val('dinersclub');
          $('input#securepay_card_number').data('validated',true);
          checkout_submit.validateFields(false);
          return;
        }
        re = new RegExp("^(?:3[0-9]{15}|(2131|1800)[0-9]{11})$");
        if (number.match(re) != null) {
          $('input#jsCardType').val('jcb');
          $('input#securepay_card_number').data('validated',true);
          checkout_submit.validateFields(false);
          return;
        }
        $('span#jsCardType').html('');
        $('input#jsCardType').val('');
        $('input#securepay_card_number').data('validated',false);
      });

      $('select#cc-expire-month,select#cc-expire-year').on('change',function() {
        checkout_submit.validateFields(false);
      });
      $('input#securepay_card_ccv').on('keyup',function() {
        checkout_submit.validateFields(false);
      });
      $('input#jsPayButton').removeAttr('disabled');
    }
  };

  checkout_submit.init();
});
