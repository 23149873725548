jQuery(function($) {
   var subscribe = {
        init: function() {
            $('#newsletter_form').on('submit', this.doSubscribe);
        },

        doSubscribe: function(e) {
            var submit = $("#newsletter_submit");
            submit.hide();
        }
 };

   subscribe.init();
});
