jQuery(function($) {
   var filters = {
       xhr : null,

       init : function() {
           var $body = $('body.page.news, body.page.events');

           filters.fixFilters();
           $body.on('click', '.news-type-filter', this.changeFilter);
           $(window).on('scroll', this.fixFilters);
           $body.on('focus','.news-search-box input', this.showCategoriesMobile);
           $body.on('click','.close-filters-on-mobile', this.closeFiltersMobile);
           $body.on('news_filters_changed', this.runFilters);
           $body.on('click', '.news-search-box a', this.runSearch);
           $body.on('keypress', '.news-search-box input', this.triggerSearch);
           $body.on('click', '.pagination .page-numbers', this.pageClicked);
           $body.on('click', '.mini-menu-search', this.scrollToSearch);
       },

       /**
        * Triggers a filter update when the page number is clicked
        * @param e
        */
       pageClicked : function(e) {
           e.preventDefault();
           $('body').trigger('news_filters_changed', $(this).data('page'));
       },

       /**
        * Triggers a filter update when a category filter is turned on or off
        * @param e
        */
       changeFilter : function(e) {
           if (!$(this).hasClass("selected")) {
               var $allFilters = $('.news-type-filter');
               $allFilters.removeClass('selected');
               $allFilters.children('a').removeClass('active');
               $(this).addClass("selected");
               $(this).children("a").addClass("active");
               $('body').trigger('news_filters_changed');
           }
       },

       triggerSearch : function(e) {
           if (e.keyCode === 13) {
               $('.news-search-box a').trigger('click');
           }
       },

       runSearch : function(e) {
           e.preventDefault();
           filters.runFilters(e);
       },

       /**
        * Runs the filters - sends an ajax request to the shop and gets the results back
        * @param e
        * @param page_no
        */
       runFilters : function(e, page_no) {
           if (filters.xhr !== null) {
               filters.xhr.abort();
               filters.xhr = null;
           }

           var data = {};

           data.post_types = filters.getTypeSearch();

           var search = $('.news-search-box input').val().trim();
           if (search.length > 0) {
               data.search = search;
           }

           if (page_no) {
               data.pageno = page_no;
           }

           $('.news-row, .pagination').fadeTo(600, '0.3');
           filters.scrollToResults();

           filters.xhr = $.ajax({
                    'url' : $(".news-filters").data('url'),
                    'method' : 'POST',
                    'dataType' : 'json',
                    'data' : data
                })
                .done( function(data) {
                    $('.news-row').html(data.results);
                    $('.pagination').html(data.pagination);
                })
                .always( function() {
                    $('.news-row, .pagination').fadeTo(600, '1');
                });
        },

        /**
         * If the filters are statically positioned, we're on mobile, so scroll to the results when a search happens
         */
        scrollToResults : function() {
            $('html, body').animate({
                scrollTop: $('.news-row').offset().top - $('.top-header').outerHeight() - 125
            }, 1000);
        },

        /**
         * Scroll search into view on click
         * @param  {Event} e
         */
        scrollToSearch : function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('.news-filters').offset().top - $('.top-header').outerHeight()
            }, 1000);
            return false;
        },

       /**
        * Gathers all the active category slugs and concat them into a comma separated string
        * @returns {*}
        */
       getTypeSearch : function() {
           var $selected = $('.news-type-filter.selected');

           var types = [];
           $selected.each(function(i) {
               types.push($(this).data('slug'));
           });

           if (types.length > 1) {
               return 'all';
           } else if (types.length === 1) {
               return types[0];
           } else {
               return 'none';
           }
       },

       /**
        * 'Stick' the search/filter section to the viewport when the user scrolls the shop up
        * @param e
        */
       fixFilters : function(e) {
           var $shop = $('.news-container');
           var $shopFilters = $(".news-filters");

           if (!$shopFilters.length) {
               return;
           }

           var position = $(window).scrollTop() - $shop.offset().top + $('.top-header').outerHeight();
           //check that the bottom of the filters don't overlap the footer
           var footer_offset = $('footer').offset().top - (position + $shopFilters.outerHeight());
           //if it does overlap, set the position to be less to avoid overlap
           position = Math.min(position, position - footer_offset);

           $shopFilters.css({'top' : Math.max(0, position) });
           $shop.css({ 'min-height' : $shopFilters.outerHeight() });

       },

       /**
        *
        * @param e
        */
       showCategoriesMobile : function(e) {
           var $cats = $(".category-filters");

           if ($cats.is(':hidden')) {
               $cats.slideDown();
           }

       },

       /**
        *
        * @param e
        * @returns {boolean}
        */
       closeFiltersMobile : function(e) {
           e.preventDefault();
           var $cats = $(".category-filters");
           $cats.slideUp();
           return false;
       }

 };

   filters.init();
});
