jQuery(function($) {
   var slide_menu = {

       init : function() {
           $('a.mini-menu-open').on('click', this.toggleMenu);
           $('a.mini-menu-search').on('click', this.toggleSearch);
       },

       toggleMenu : function(e) {
           var menu = $('.slide-menu');

           e.preventDefault();
           if (menu.hasClass('closed')) {
               menu.animate({
                    right: 0
                }, 1000, function() {
                    menu.removeClass('closed');
               });
           } else {
               menu.animate({
                    right: '-' + menu.outerWidth() + 'px'
                }, 1000, function() {
                    menu.addClass('closed');
               });
           }

           return false;
       },

       toggleSearch : function(e) {
           e.preventDefault();
           var search = $('.site-search-form');
           search.fadeToggle('slow');
           return false;
       }


 };

   slide_menu.init();
});
