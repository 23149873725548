jQuery(function($) {
    var book_sliders = {
        init : function() {

            if ($('.featured-book-slider').length > 0) {
                this.initFeaturedSlider();
            }

            if ($('.latest-book-slider').length > 0) {
                this.initLatestSlider();
            }
        },

        initFeaturedSlider : function() {
            $('.featured-book-slider').slick({
                infinite : true,
                speed : 800,
                slidesToShow : 4,
                slidesToScroll: 4,
                prevArrow : "<i class='fa fa-2x fa-angle-left prev'></i>",
                nextArrow : "<i class='fa fa-2x fa-angle-right next'></i>",
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 990,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
        },

        initLatestSlider : function() {
            $('.latest-book-slider').slick({
                infinite : true,
                speed : 800,
                slidesToShow : 4,
                slidesToScroll: 4,
                prevArrow : "<i class='fa fa-2x fa-angle-left prev'></i>",
                nextArrow : "<i class='fa fa-2x fa-angle-right next'></i>",
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 990,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
        }
    };

    book_sliders.init();
});
