jQuery(function($) {
   var home_slider = {
       init : function() {

           if ($('.home-slider').length > 0) {
               this.initBannerSlider();
           }

           if ($('.highlight-slider').length > 0) {
               this.initHighlightSlider();
               $(window).resize(this.fitHighlightSlider);
           }
       },

       fitHighlightSlider: function() {
           var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
           if (!isMobile) {
               $('.highlight-slider').slick('refresh');
           }
       },

       initHighlightSlider : function() {
           $('.highlight-slider').slick({
               infinite : true,
               speed : 800,
               slidesToShow : 1,
               centerMode : true,
               variableWidth : true,
               centerPadding : 0,
               prevArrow : "<i class='fa fa-2x fa-angle-left prev'></i>",
               nextArrow : "<i class='fa fa-2x fa-angle-right next'></i>",
               responsive: [
                   {
                       breakpoint: 575,
                       settings: {
                           centerPadding: '20%'
                       }
                   }
               ]
          });
      },

      initBannerSlider : function() {
          $('.home-slider').slick({
              infinite : true,
              speed : 1000,
              slidesToShow : 1,
              autoplay : true,
              autoplaySpeed : 8000,
              prevArrow : "<i class='fa fa-2x fa-angle-left prev'></i>",
              nextArrow : "<i class='fa fa-2x fa-angle-right next'></i>"
         });
      }
 };

   home_slider.init();
});
