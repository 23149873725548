jQuery(function($) {
    var about = {
        init : function() {
            var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                $("body.about .about-sections").responsiveTabs({
                    accordionOn: ["xs", "sm"]
                });
                $('body.about .accordion-link').removeClass('active');
                $('body.about .tab-pane').addClass('hidden');
            }
        }
    };

    about.init();

});
