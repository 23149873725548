jQuery(function($) {
   var filters = {
       xhr : null,

       init : function() {
           var $body = $('body.woocommerce.post-type-archive-product');
           var $checkout = $('body.checkout');

           filters.fixFilters();
           $body.on('click', '.category-filter', this.changeFilter);
           $body.on('change', '.category-select-filter', this.changeSelectFilter);
           /*disable temporarily @todo: fix the overflow issue
           $(window).on('scroll', this.fixFilters);
           $(window).on('resize', this.fixFilters);*/
           $body.on('focus','.search-box input', this.showCategoriesMobile);
           $body.on('click','.close-filters-on-mobile', this.closeFiltersMobile);
           $body.on('shop_filters_changed', this.runFilters);
           $body.on('click', '.shop-filters .search-box a', this.changeSearch);
           $body.on('keypress', '.shop-filters .search-box input', this.triggerSearch);
           $body.on('click', '.search-item', this.removeSearch);
           $body.on('click', '.pagination .page-numbers', this.pageClicked);
           $checkout.on('click', 'input#place_order', this.refreshReminder);

           $('input#giftwrap_option').on('change', function(e) {
               $('body').trigger('update_checkout');
           });
       },

       /**
        * Triggers a filter update when the page number is clicked
        * @param e
        */
       pageClicked : function(e) {
           e.preventDefault();
           $('body').trigger('shop_filters_changed', $(this).data('page'));
       },

       /**
        * Triggers a filter update when a category filter is turned on or off
        * @param e
        */
       changeFilter : function(e) {
           var selected = $(this);
           if (!selected.hasClass('selected')) {
               $('.category-filter.selected').removeClass('selected');
               selected.addClass('selected');
           } else {
               selected.removeClass('selected');
           }
           $('body').trigger('shop_filters_changed');
       },

       /**
        * Triggers a filter update when a category filter is selected
        * @param e
        */
       changeSelectFilter : function(e) {
           $(this).find('option').removeClass("selected");
           if ($(this).find(":selected").text() !== 'All') {
                $(this).find(":selected").toggleClass("selected");
           }

           $('body').trigger('shop_filters_changed');
       },

       triggerSearch : function(e) {
           if (e.keyCode === 13) {
               $('.shop-filters .search-box a').trigger('click');
           }
       },

       /**
        * Searches for a new term when the user submits in search input
        * @param e
        * @returns {boolean}
        */
       changeSearch : function(e) {
           e.preventDefault();
           var $search = $('#search');
           var search_val = $search.val().trim();
           if (search_val.length > 0) {
               var search_term = "<span class='search-item' data-term='" + search_val + "'>" + search_val + "</span>";
               $(".previous-searches").html(search_term);
           } else {
               $(".previous-searches").html("");
           }
           $('body').trigger('shop_filters_changed');
           $search.val("");
           filters.fixFilters();
           return false;
       },

       /**
        * Removes a search term and triggers a filter update when a search is removed
        * @param e
        */
       removeSearch : function(e) {
           $(this).detach();
           filters.fixFilters();
           $('body').trigger('shop_filters_changed');
       },

       /**
        * Check if the search term already exists in the list of searches
        * @param searchVal
        * @returns {boolean}
        */
       isDuplicateSearch : function(searchVal) {
           return $(".search-item[data-term='" + searchVal + "']").length > 0;
       },

       /**
        * Runs the filters - sends an ajax request to the shop and gets the results back
        * @param e
        * @param page_no
        */
       runFilters : function(e, page_no) {
           if (filters.xhr !== null) {
               filters.xhr.abort();
               filters.xhr = null;
           }

           var data = {};

           var cats = filters.getCategorySearch();
           if (cats) {
               data.product_cat = cats;
           }

           var search = filters.getSearchTerms();
           if (search) {
               data.s = search;
           }

           if (page_no) {
               data.paged = page_no;
           }

           $('.shop-book-row.main-book-loop, .pagination').css('opacity', '0.3');
           filters.scrollToResults();

           filters.xhr = $.ajax({
                    'url' : $(".shop-filters").data('url'),
                    'method' : 'POST',
                    'dataType' : 'json',
                    'data' : data
                })
                .done( function(data) {
                    $('.shop-book-row.main-book-loop').html(data.results);
                    $('.pagination').html(data.pagination);
                })
                .always( function() {
                    $('.shop-book-row.main-book-loop, .pagination').fadeTo(600, '1');
                });
        },

        /**
         * If the filters are statically positioned, we're on mobile, so scroll to the results when a search happens
         */
        scrollToResults : function() {
            $('html, body').animate({
                scrollTop: $('.shop-book-row.main-book-loop').offset().top - $('.top-header').outerHeight() - 125
            }, 1000);
        },

        /**
         * Scroll search into view on click
         * @param  {Event} e
         */
        scrollToSearch : function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('.shop-filters').offset().top - $('.top-header').outerHeight()
            }, 1000);
            return false;
        },

       /**
        * Gathers all the active search terms and conct them into a string
        * @returns {*}
        */
        getSearchTerms : function() {
           var $terms = $('.search-item');
           if (!$terms.length) {
               return false;
           }

           var search_terms = [];
           $terms.each(function(i) {
               search_terms.push($(this).data('term'));
           });
           return search_terms.join(" ");
        },

       /**
        * Gathers all the active category slugs and concat them into a comma separated string
        * @returns {*}
        */
       getCategorySearch : function() {
           var $selected = $('.category-filter.selected');
           if (!$selected.length) {
               return false;
           }

           var cats = [];
           $selected.each(function(i) {
               cats.push($(this).data('slug'));
           });
           return cats.join();
       },

       /**
        * 'Stick' the search/filter section to the viewport when the user scrolls the shop up
        * @param e
        */
       fixFilters : function(e) {
           var $shop = $('.shop-container');
           var $shopFilters = $(".shop-filters");

           if (!$shopFilters.length) {
               return;
           }

           var position = $(window).scrollTop() - $shop.offset().top + $('.top-header').outerHeight();
           //check that the bottom of the filters don't overlap the footer
           var footer_offset = $('footer').offset().top - (position + $shopFilters.outerHeight());
           //if it does overlap, set the position to be less to avoid overlap
           position = Math.min(position, position - footer_offset);

           $shopFilters.css({'top' : Math.max(0, position) });
           $shop.css({ 'min-height' : $shopFilters.outerHeight() });

       },

       /**
        *
        * @param e
        */
       showCategoriesMobile : function(e) {
           var $cats = $(".category-filters");

           if ($cats.is(':hidden')) {
               $cats.slideDown();
           }

       },

       /**
        *
        * @param e
        * @returns {boolean}
        */
       closeFiltersMobile : function(e) {
           e.preventDefault();
           var $cats = $(".category-filters");
           $cats.slideUp();
           return false;
       },

       refreshReminder: function(e) {

           var $modal = $("#checkout-refresh-modal");

           $modal.modal();
       }

 };

   filters.init();
});
