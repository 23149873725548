jQuery(function($) {
   var versions = {
       selectors : $('.variation-selector'),

       init : function() {
           this.selectors.on('click', this.changeSelectedVariation);

           var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
           if (isMobile) {
               $("body.single-product .single-product-tab").responsiveTabs({
                   accordionOn: ["xs", "sm"]
               });
               $('body.single-product .accordion-link').removeClass('active');
               $('body.single-product .tab-pane').addClass('hidden');
           }
       },

       changeSelectedVariation : function(e) {
           if ($(this).hasClass('active')) {
               return;
           }

           //update class on active items
           versions.selectors.removeClass('active');
           $(this).addClass('active');

           //update hidden variation id field
           $('input.variation_id').val($(this).data('variation_id'));

           //update hidden attribute fields
           var atts = $(this).data('attributes');
           $.each(atts, function(key, value) {
               $("#" + key).val(value);
           });
       },
    };

    var preview = {

     init : function() {
         this.previewPDF();
     },

     previewPDF : function() {
         $(".preview-pdf").each(function(i) {
             var options = {
                 pdfOpenParams: {
                     'view' : 'fitV',
                     'fallbackLink' : '<p>This browser does not support this PDF viewer. ' +
                     'Please try another browser or <a href="[url">download</a> the PDF to view it.</p>'
                 }
             };
             PDFObject.embed($(this).data('preview'), "#" + $(this).attr('id'), options);
             if ($(this).children('embed').length > 0) {
                 $(this).css('height', '85vh');
             }
         });
     },

    };

   versions.init();
   preview.init();

});
