jQuery(function($) {
    var rights = {

     init : function() {
         this.rightsPDFs();
     },

     rightsPDFs : function() {
         $(".rights-pdf").each(function(i) {
             var options = {
                 pdfOpenParams: {
                     'view' : 'fitV',
                     'fallbackLink' : '<p>This browser does not support this PDF viewer. ' +
                     'Please try another browser or <a href="[url">download</a> the PDF to view it.</p>'
                 }
             };
             PDFObject.embed($(this).data('document'), "#" + $(this).attr('id'), options);
             if ($(this).children('embed').length > 0) {
                 $(this).css('height', '70vh');
             }
         });
     }

    };

   rights.init();
});
