jQuery(function($) {
    // Javascript to enable link to tab
    var hash = document.location.hash;
    var prefix = "tab_";
    if (hash) {
        var id = hash.replace(prefix, "");
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            var $accordionLink = $('.accordion-link[href="' + id + '"]');
            $accordionLink.addClass('active');
            $(id).removeClass('hidden').addClass('active');
        } else {
            $('.nav-tabs a').removeClass('active');
            $('.tab-pane').removeClass('active');
            var $tabLink = $('.nav-tabs a[href="' + id + '"]');
            var $tabContent = $('.tab-pane'+id);
            $tabContent.addClass('active');
            $tabLink.attr('aria-expanded', 'true');
            $tabLink.addClass('active');
            $tabLink.parent().attr('aria-expanded', 'true');
            $tabLink.tab('show');
        }

        $('html, body').animate({
            scrollTop: $(id).offset().top - $('.top-header').outerHeight() - 125
        }, 600);
    }

// Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash.replace("#", "#" + prefix);
    });

    var $accordionLinks = $('.accordion-link');
    var $responsiveTabs = $('.responsive-tabs-container .tab-pane');

   $accordionLinks.on('click', function() {
        var $id = $(this).attr('href');
        var notSelf = $($id).hasClass('hidden');
        $accordionLinks.removeClass('active');
        $responsiveTabs.slideUp().promise().done(function() {
            $responsiveTabs.addClass('hidden');
        });
       if (notSelf) {
           $(this).addClass('active');
           $($id).removeClass('hidden');
           $($id).slideDown().promise().done(function() {
               $('html, body').animate({
                   scrollTop: $($id).offset().top - $('.top-header').outerHeight() - 125
               }, 400);
           });
       }
    });
});