jQuery(function($) {
   var contact = {
       container: $('#google_map'),
       latlng: {},

       init : function() {
           if (this.container.length > 0) {
               this.latlng = {
                   lat: this.container.data('lat'),
                   lng: this.container.data('lng')
               };

               this.viewMap();
           }
       },

       viewMap : function() {
           var map = new google.maps.Map(this.container.get(0), {
               center: this.latlng,
               zoom: 12,
               styles: [
                   {
                       "featureType": "all",
                       "elementType": "geometry",
                       "stylers": [
                           {
                               "color": "#001049"
                           }
                       ]
                   },
                   {
                       "featureType": "all",
                       "elementType": "geometry.stroke",
                       "stylers": [
                           {
                               "color": "#ff0000"
                           },
                           {
                               "visibility": "off"
                           }
                       ]
                   },
                   {
                       "featureType": "all",
                       "elementType": "labels.text.fill",
                       "stylers": [
                           {
                               "color": "#ffffff"
                           }
                       ]
                   },
                   {
                       "featureType": "all",
                       "elementType": "labels.text.stroke",
                       "stylers": [
                           {
                               "visibility": "off"
                           }
                       ]
                   },
                   {
                       "featureType": "all",
                       "elementType": "labels.icon",
                       "stylers": [
                           {
                               "hue": "#00aaff"
                           },
                           {
                               "lightness": "18"
                           },
                           {
                               "saturation": "59"
                           }
                       ]
                   },
                   {
                       "featureType": "landscape",
                       "elementType": "all",
                       "stylers": [
                           {
                               "color": "#ffffff"
                           }
                       ]
                   },
                   {
                       "featureType": "landscape",
                       "elementType": "geometry",
                       "stylers": [
                           {
                               "color": "#00a0ac"
                           }
                       ]
                   },
                   {
                       "featureType": "landscape",
                       "elementType": "geometry.stroke",
                       "stylers": [
                           {
                               "weight": "0.01"
                           },
                           {
                               "visibility": "off"
                           }
                       ]
                   },
                   {
                       "featureType": "landscape.natural.landcover",
                       "elementType": "geometry.fill",
                       "stylers": [
                           {
                               "color": "#873030"
                           }
                       ]
                   }
               ]
           });

           var marker = new google.maps.Marker({
               position: this.latlng,
               map: map
           });
       }
   };

   contact.init();
});